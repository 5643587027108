import { createEffect, createSignal, onCleanup } from "solid-js";
import { View, Text, hexColor, Show } from "@lightningjs/solid";
import { useNavigate, useParams } from "@solidjs/router";
import FirstPageBackground from "../../components/FirstPageBackground";
import styles from "../../styles";
import { isVpnMode, setIsPlaying, setIsVpnMode } from "../../state";
import { Button, ButtonContainer, Column, Row } from "@lightningjs/solid-ui";
import Background from "../../components/Background";

const SettingsPage = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [data, setData] = createSignal(null);
    const [packageName, setPackage] = createSignal(null);

    createEffect(() => {
        const storedData = localStorage.getItem("licenceData");
        if (storedData) {
            try {
                // Parse the JSON string into an object
                const parsedData = JSON.parse(storedData);
                setData(parsedData);
                console.log('storedData', parsedData);
                

            } catch (error) {
                console.error("Failed to parse storedData:", error);
            }
        }
    });

    function formatDate(dateString) {
        const date = new Date(dateString);
        return date.toLocaleString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
            // timeZoneName: 'short'
        });
    }
    

    return (
        <View
            onEscape={() => {
                navigate("/home");
            }}
        >
            <Show when={data()}>
                <FirstPageBackground />
                <View x={150} y={100}>
                    <Text style={{ fontSize: 72 }}>Settings</Text>

                    <View y={120} borderRadius={25} color={hexColor("#223344")} width={1350} height={80}>
                        <Text y={15} style={{ fontSize: 37 }} x={20}>Package Name</Text>
                        <Text y={15} style={{ fontSize: 37 }} x={900}>{data().package_name}</Text>
                    </View>

                    <View y={220} borderRadius={25} color={hexColor("#223344")} width={1350} height={80}>
                        <Text y={15} style={{ fontSize: 37 }} x={20}>Device ID</Text>
                        <Text y={15} style={{ fontSize: 37 }} x={900}>{data().deviceid}</Text>
                    </View>

                    <View y={320} borderRadius={25} color={hexColor("#223344")} width={1350} height={80}>
                        <Text y={15} style={{ fontSize: 37 }} x={20}>License End Date</Text>
                        <Text y={15} style={{ fontSize: 37 }} x={900}>{formatDate(data().license_end_date)}</Text>
                    </View>

                    <View y={420} borderRadius={25} color={hexColor("#223344")} width={1350} height={80}>
                        <Text y={15} style={{ fontSize: 37 }} x={20}>PROXY License End Date</Text>
                        <Text y={15} style={{ fontSize: 37 }} x={900}>{formatDate(data().vpn_license_end_date)}</Text>
                    </View>

                </View>
            </Show>
        </View>
    );
};

export default SettingsPage;
