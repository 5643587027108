import { View } from "@lightningjs/solid";
import { hexColor } from "@lightningjs/solid";
const FirstPageBackground = (type: any) => {
  var keek = type;
  console.log('kevi nben log', keek);
  
  return (
    <View
      src=""
      width={1920}
      height={1080}
      positionAbsolute={0}
      // color={
      //   hexColor("#0x4F7F4F")
      // }
      color={hexColor("#080808")}

    />
  );
};

export default FirstPageBackground;
