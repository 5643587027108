import { createEffect, on } from "solid-js";
import {
  globalStream,
  globalStreamType,
  playListPassword,
  playListUrl,
  playListUsername,
} from "./state";

let manifestUri = "";
let avPlayer;

function initializeAVPlayer() {
  if (!avPlayer) {
    avPlayer = webapis.avplay; // Initialize avPlayer
  }

  var listener = {
    onbufferingstart: function () {
      console.log("Buffering start.");
    },
    onbufferingprogress: function (percent) {
      console.log("Buffering progress data: " + percent);
    },
    onbufferingcomplete: function () {
      console.log("Buffering complete.");
      avPlayer.play(); // Ensure playback starts as soon as buffering is complete
    },
    oncurrentplaytime: function (currentTime) {
      console.log("Current playtime: " + currentTime);
    },
    onevent: function (eventType, eventData) {
      console.log("Event type: " + eventType + ", data: " + eventData);
    },
    onerror: function (eventType) {
      console.log("Error type: " + eventType);
    },
    onsubtitlechange: function (duration, text, data3, data4) {
      console.log("Subtitle Text: " + text);
    },
    ondrmevent: function (drmEvent, drmData) {
      console.log("DRM callback: " + drmEvent + ", data: " + drmData);
    },
  };

  avPlayer.setListener(listener); // Re-attach listeners on initialization
}

document.addEventListener("DOMContentLoaded", function () {
  initializeAVPlayer();

  createEffect(
    on(
      [globalStream, globalStreamType],
      ([str, type]) => {
        if (type === "live") {
          console.log("Live stream selected:", str);
          manifestUri = `${playListUrl()}/live/${playListUsername()}/${playListPassword()}/${str}.ts`;
        } else if (type === "splash") {
          console.log("Non-live stream selected");
        }

        avPlayer.stop();
        avPlayer.open(manifestUri);
        avPlayer.setDisplayRect(0, 0, 1920, 1080);
        avPlayer.prepareAsync(
          () => {
            console.log("Preparation complete for:", manifestUri);
            avPlayer.play();
          },
          (error) => {
            console.log("Preparation error:", error);
          }
        );

        console.log("The video source has been updated to:", manifestUri);
      },
      { defer: true }
    )
  );

  avPlayer.stop();
  avPlayer.open(manifestUri);
  avPlayer.setDisplayRect(0, 0, 1920, 1080);
  avPlayer.prepareAsync(
    () => {
      console.log("Initial preparation complete for:", manifestUri);
      avPlayer.play();
    },
    (error) => {
      console.log("Initial preparation error:", error);
    }
  );
});

function onErrorEvent(event) {
  onError(event.detail);
}

function onError(error) {
  console.error("Error code", error.code, "object", error);
}

export function playVideo() {
  if (!avPlayer) {
    console.error("AV Player is not initialized.");
    return;
  }
  const video = document.getElementById("av-player");
  video.hidden = false;
  setTimeout(() => video.play(), 50);
  video.focus();
  return video;
}

export function closeVideo() {
  if (!avPlayer) {
    console.error("AV Player is not initialized.");
    return;
  }

  // Hide the video element
  const video = document.getElementById("av-player");
  video.hidden = true;

  try {
    // Stop the player if it's playing or buffering
    if (
      avPlayer.getState() === "PLAYING" ||
      avPlayer.getState() === "BUFFERING"
    ) {
      console.log("Stopping video due to PLAYING or BUFFERING state.");
      avPlayer.stop();
    }

    // Reset listeners and remove any callbacks
    avPlayer.setListener(null);
    console.log("Listeners removed and player stopped.");

    // Clear AVPlayer reference to ensure no lingering references
    avPlayer = null;
  } catch (error) {
    console.error("Error while stopping the player:", error);
  }

  video.classList.add("hidden");
  return video;
}
