
import {
    //@ts-ignore
    createEffect, createMemo, on, createSignal, createSelector, For, onMount
} from "solid-js";
//@ts-ignore
import { ElementNode, IntrinsicTextNodeStyleProps, View, activeElement, hexColor, Text, Show } from "@lightningjs/solid";
import { Button, ButtonContainer, Column, Row } from "@lightningjs/solid-ui";
import { useNavigate, useParams } from "@solidjs/router";
import { TileRow, TileRowCategories, TileRowMovie, TileRowRecomandation } from "../../components";
import styles from "../../styles";
import { playListPassword, playListUrl, playListUsername, setGlobalBackground } from "../../state";
import moviesProvider from "../../api/providers/movies";
import moviesCategoriesProvider from "../../api/providers/movies";
import fetchDatasInfo from "../../api/providers/moviesInfo";
import { createInfiniteScroll } from "../../components/pagination";
import ContentBlock from "../../components/ContentBlock";
import { assertTruthy } from "@lightningjs/renderer/utils";
import { debounce } from "@solid-primitives/scheduled";
import axios from 'axios';
import moviesCategories from "../../api/providers/moviseCategories";
import moviesCategory from "../../api/providers/moviesCategory";
import FirstPage from "../LicenceScreen/Licence";
import FirstPageBackground from "../../components/FirstPageBackground";

const RowStyles: any = {
    display: "flex",
    justifyContent: "flexStart",
    width: 300,
    height: 300,
    color: hexColor("00000000"),
    gap: 26,
    y: 400,
} satisfies IntrinsicTextNodeStyleProps;

const Movies = () => {

    const params = useParams();
    const [columnY, setcolumnY] = createSignal(0);
    const [heroContent, setHeroContent] = createSignal({});
    const navigate = useNavigate();
    const [data, setData] = createSignal(null);
    const isFirst = createSelector(() => {
        return 0;
    });

    const providerCategory = createMemo(() => {
        return createInfiniteScroll(
            moviesCategory(params.filter || "all")
        );
    });

    createEffect(() => {
        // const username = playListUsername();
        // const password = playListPassword();
        // const playList = playListUrl();


        // axios.get(`${playList}/player_api.php?username=${username}&password=${password}&action=get_vod_categories`)
        //     .then(response => setData(response.data))
        //     .catch(error => console.error(error));

        const kevi = providerCategory().pages();
        if (providerCategory().pages()) {
            //@ts-ignore
            setData(kevi)

        }
    });

    const [number, setNumber] = createSignal(517); // Default number is 1
    const [isNumberChanging, setIsNumberChanging] = createSignal(false);


    let timeoutId: ReturnType<typeof setTimeout> | null = null;

    const handleNumberChange = (newNumber: any) => {


        if (number() === newNumber) {
            return;
        }


        // Clear the previous timeout if it exists
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        setIsNumberChanging(true);
        // Set a new timeout
        timeoutId = setTimeout(() => {
            setNumber(newNumber);
            setIsNumberChanging(false);
            timeoutId = null; // Reset the timeout ID
        }, 500); // Adjust the delay as needed
    };

    const provider = createMemo(() => {
        return createInfiniteScroll(moviesProvider(params.filter || "all", number()));
    });



    const delayedBackgrounds = debounce(
        (img: string) => setGlobalBackground(img),
        400
    );
    const delayedHero = debounce(
        (content: {}) => setHeroContent(content || {}),
        200
    );

    createEffect(
        on(
            activeElement,
            (elm: any) => {
                if (elm.backdrop) {
                    delayedBackgrounds(elm.backdrop);
                }

                if (elm.heroContent) {
                    delayedHero(elm.heroContent);
                }
            },
            { defer: true }
        )
    );

    function onRowFocus(this: any) {

        this.children.selected?.setFocus();
        setcolumnY((this.y || 0) * -1 + 24);
        let numPages = provider().pages().length;

        if (
            numPages === 0 ||
            (this.parent.selected && this.parent.selected >= numPages - 2)
        ) {
            provider().setPage((p) => p + 1);
        }

    }

    function onEnter(this: ElementNode) {
        //@ts-ignore
        let entity = this.children.selected;
        console.log('shiko data', entity);

        assertTruthy(entity && entity.href);
        navigate(entity.href);

        return true;
    }

    let myButton;
    let viewRef;
    let search;






    return (
        <View onEscape={() => {
            navigate("/home")
        }}>
            <FirstPageBackground />
            <View x={1700} y={200} onEnter={() => navigate("/search")} style={{
                borderRadius: 50,
                width: 100,
                height: 100,
                scale: 1,

                zIndex: 2,
                color: 0x4F7F4F,
                transition: { scale: { duration: 250, easing: "ease-in-out" } },
                border: { width: 0, color: 0x00000000 },
                focus: { scale: 1.1, color: "0x467ffa" }

            }} ref={search} onDown={() => myButton.setFocus()} >
                <View x={25} y={25} width={50} height={50} src="https://i.ibb.co/2M3VzWT/magnifying-glass.png">

                </View>

            </View>
            <ContentBlock y={180} x={162} content={heroContent()} />

            <Show
                when={providerCategory().pages() && providerCategory().pages().length > 0}
                fallback={<View x={200} y={500}><Text>Loading ....</Text></View>}
            >
                <View onUp={() => search.setFocus()} onDown={() => viewRef.setFocus()}>
                    <Row transition={{ x: { duration: 350 } }} ref={myButton} x={160} gap={40} style={RowStyles}>
                        <For each={providerCategory().pages()}>
                            {(categorie, i) => (
                                <ButtonContainer autofocus={isFirst(i())} index={i()} states={"active"}
                                    onFocus={() => { handleNumberChange(categorie.categoryID) }}
                                    //@ts-ignore
                                    style={{
                                        borderRadius: 7,
                                        width: 385,
                                        height: 85,
                                        scale: 1,
                                        zIndex: 2,
                                        color: 0x4F7F4F,
                                        transition: { scale: { duration: 250, easing: "ease-in-out" } },
                                        border: { width: 0, color: 0x00000000 },
                                        focus: { scale: 1.1, color: "0x467ffa" }
                                    }}
                                >
                                    <Text style={{ fontSize: 28 }}>
                                        {categorie.category_name.length > 20
                                            ? `${categorie.category_name.substring(0, 20)}...`
                                            : categorie.category_name}

                                    </Text>
                                </ButtonContainer>
                            )}
                        </For>
                    </Row>
                </View>

                <View onUp={() => {
                    myButton.setFocus()
                }} clipping style={styles.itemsContainer}>
                    <Show
                        when={provider().pages() && provider().pages().length > 0 && !isNumberChanging()}
                        fallback={<View x={200} y={100}><Text>Loading ....</Text></View>}
                    >

                        <Column
                            plinko
                            ref={viewRef}
                            announce="Movies"
                            y={columnY()}
                            scroll="none"
                            style={styles.Column}
                        >
                            <For each={provider().pages()}>
                                {(items, i) => {

                                    return (
                                        <TileRowRecomandation
                                            items={items}
                                            width={1620}
                                            onFocus={onRowFocus}
                                            onEnter={onEnter}
                                        />
                                    )
                                }}
                            </For>
                        </Column>
                    </Show>

                </View>
            </Show>
        </View>
    );
};

export default Movies;