import {
    createEffect,
    onCleanup,
    createMemo,
    on,
    createSignal,
    createSelector,
    onMount
} from "solid-js";
//@ts-ignore
import { ElementNode, IntrinsicTextNodeStyleProps, View, hexColor, setActiveElement, Text } from "@lightningjs/solid";
import { playVideo, closeVideo } from "../../../src/video.js";
import { useNavigate } from "@solidjs/router";
import { setGlobalBackground, setGlobalStream, setGlobalStreamType, setIsPlaying } from "../../state";
import { useParams, useLocation } from "@solidjs/router";




const RowStyles: any = {
    display: "flex",
    justifyContent: "flexStart",
    width: 300,
    height: 300,
    color: hexColor("00000000"),
    gap: 26,
    y: 400,
} satisfies IntrinsicTextNodeStyleProps;

const Player = () => {
    const navigate = useNavigate();

    const [currentIndex, setCurrentIndex] = createSignal(null);
    const location = useLocation();
    function getQueryParams(search) {
        return new URLSearchParams(search);
    }


    createEffect(() => {
        console.log('Query parameters:', location.search);

        const params = getQueryParams(location.search);
        const num = params.get('str');


        // Ensure currentIndex is set initially
        if (num !== null) {
            //@ts-ignore
            setCurrentIndex(num);
        }
    });

    createEffect(() => {
        console.log('Effect triggered');
        setIsPlaying(false);

        const timeoutId = setTimeout(() => {
            const videoElement = document.getElementById('video');
            const numriFinal = currentIndex();


            //@ts-ignore
            setGlobalStream(numriFinal);

            setGlobalBackground('');

            if (videoElement) {
                //@ts-ignore
                const video = playVideo(videoElement);
                // @ts-ignore
                setActiveElement(video);


                console.log('Video started, global stream and type set');
            } else {
                console.error('Video element not found');
            }
        }, 100); // Adjust the delay as necessary

        // Clean up on effect re-run
        onCleanup(() => {
            clearTimeout(timeoutId);
        });
    });


    function onEscape() {
        closeVideo();
        // // Set focus back to lightning app
        // document.getElementsByTagName("canvas")[0].focus();
        // entityActions.setFocus();
        // setBackdropAlpha(0);
        setTimeout(() => navigate(-1), 100);
        setIsPlaying(true);
    }


    return (
        <View onEscape={() => { onEscape() }} >

        </View>
    );
};

export default Player;