import {
  createEffect,
  createSignal,
  //@ts-ignore
  Show,
  createSelector,
  //@ts-ignore
  For,
  onCleanup,
  onMount,
} from "solid-js";
import { View, Text, hexColor } from "@lightningjs/solid";
import { Column, Button, ButtonContainer, Row } from "@lightningjs/solid-ui";
import { useNavigate } from "@solidjs/router";
import axios from "axios";
import FirstPageBackground from "../../components/FirstPageBackground";
import {
  setIsPlaying,
  setPlayListPassword,
  setPlayListUrl,
  setPlayListUsername,
} from "../../state";
import QrCode from "../../components/QRcode";
import styles from "../../styles";
import CryptoJS from 'crypto-js';
import QrCodeWithAxios from "../../components/QRcodeaxios";

const ITEMS_PER_PAGE = 4; // Number of items to display at a time


const FallbackView = () => (
  <View>
    <Text style={{ fontSize: 32, fontWeight: 'bold' }}>Instructions</Text>
    <Text y={70} style={{ fontSize: 30, fontWeight: 'bold', color: hexColor("#FFA500FF") }}>Step 1: Scan the QR Code</Text>
    <Text y={115} style={{ fontSize: 20 }} >
      Locate the QR code on your right.{"\n"}
      Use your smartphone's camera or a QR code scanner app to scan it. This will take you to the next step.
    </Text>
    <Text y={190} style={{ fontSize: 30, fontWeight: 'bold', color: hexColor("#FFA500FF") }}>Step 2: Enter Playlist Information</Text>
    <Text y={235} style={{ fontSize: 20 }}>
      After scanning the QR code, you'll be prompted to enter your playlist information.{"\n"}
      Make sure to fill in all the required fields.
    </Text>
    <Text y={300} style={{ fontSize: 30, fontWeight: 'bold', color: hexColor("#FFA500FF") }}>Step 3: Return to the App</Text>
    <Text y={345} style={{ fontSize: 20 }}>
      Once you've entered your playlist details, you can go back to the app and continue your experience.
    </Text>
  </View>
);


const FirstPage = () => {
  const [data, setData] = createSignal([]);
  const [deviceId, setDeviceId] = createSignal("");
  const [newToken, setNewToken] = createSignal("");
  const [currentIndex, setCurrentIndex] = createSignal(0);
  const [currentIndex2, setCurrentIndex2] = createSignal(0);
  const [response, setResponse] = createSignal(null);
  const [focusedIndex, setFocusedIndex] = createSignal(0);
  const [licenceEndDate, setLicenceEndDate] = createSignal(null);
  const [whiteList, setWhiteList] = createSignal(false);
  const [packageName, setPackageName] = createSignal("");

  const API_URL =
    "https://deviceapi.orbixplay.com/app/live-lbgjx/endpoint/getmydata";
  const API_TOKEN =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJiYWFzX2RldmljZV9pZCI6IjAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMCIsImJhYXNfZG9tYWluX2lkIjoiNjU0NDFiZjQ1OWRjYzYyNWI1YjY4ZGJlIiwiZXhwIjoxNzI3MzQ0NDE1LCJpYXQiOjE3MjczNDI2MTUsImlzcyI6IjY2ZjUyODE3MTczNTA0MTg4MWYwZDk0OSIsImp0aSI6IjY2ZjUyODE3MTczNTA0MTg4MWYwZDk0ZCIsInN1YiI6IjY2NWVlNzcyZjJiMDNjMmY0ZDlhOGM0YiIsInR5cCI6ImFjY2VzcyIsInVzZXJfZGF0YSI6eyJfaWQiOiI2NjVlZTc3MmYyYjAzYzJmNGQ5YThjNzIiLCJtb25nb191c2VyX2lkIjoiNjY1ZWU3NzJmMmIwM2MyZjRkOWE4YzRiIiwiZGV2aWNlaWQiOiIxODo0Qzo4NDpBRjo1Qjo5NyIsImNyZWF0ZWRfYXQiOnsiJGRhdGUiOnsiJG51bWJlckxvbmciOiIxNzE3NDk1NjY2NzIyIn19LCJwbGF5bGlzdHMiOltdLCJsaWNlbnNlX2VuZF9kYXRlIjp7IiRkYXRlIjp7IiRudW1iZXJMb25nIjoiMTczMDEwOTYwMDAwMCJ9fSwicGFja2FnZV9uYW1lIjoiRnJlZSBUcmlhbCIsInZwbl9saWNlbnNlX2VuZF9kYXRlIjp7IiRkYXRlIjp7IiRudW1iZXJMb25nIjoiMTc1NjM3MTYwMDAwMCJ9fSwiaWQiOiI2NjVlZTc3MmYyYjAzYzJmNGQ5YThjNzIiLCJuYW1lIjoiQmFzaWMifX0.6Xm8FwbiDw4mP07dks8EzOXHKE8NReAR6eWye9bY28Y";
  const navigate = useNavigate();

  // console.log("DUID: " + webapis.productinfo.getDuid());

  const parseISODate = (isoDateString) => {
    return isoDateString ? new Date(isoDateString) : null;
  };

  // const generate12DigitId = (inputString: string) => {
  //   console.log(inputString);
  //   try {
  //     const hash = CryptoJS.SHA256(inputString).toString(CryptoJS.enc.Hex);
  //     const shortHash = hash.substring(0, 12).toUpperCase();
  //     return shortHash.substring(0, 2) + ':' +
  //       shortHash.substring(2, 4) + ':' +
  //       shortHash.substring(4, 6) + ':' +
  //       shortHash.substring(6, 8) + ':' +
  //       shortHash.substring(8, 10) + ':' +
  //       shortHash.substring(10, 12);
  //   } catch (error) {
  //     console.error("Error generating ID:", error);
  //     return "No ID found"; // Trajtimi i gabimit sipas nevojave të aplikacionit tuaj
  //   }
  // };

  createEffect(() => {
    // Clear the specific item from localStorage
    localStorage.removeItem("moviesData");
    localStorage.removeItem("seriesData");

  });

  createEffect(() => {
    const fetchData = async () => {
      //@ts-ignore
      // var duid = webapis.productinfo.getDuid();
      try {
        const response = await axios.post(
          "https://logindeviceapi.orbixplay.com",
          {
            // deviceid: generate12DigitId(duid),
            deviceid: "18:4C:84:AF:5B:97",

            orbixplay_id: "8A:2C:16:F1:A8:A0",
            device_mac_address: "",
            app_name: "8A:2C:16:F1:A8:A0",
            app_version: 2,
            ntype: 1,
            hdmi: true,
            firmware: "DeviceInfo.getApplicationName()",
            device_brand: "DeviceInfo.getBrand()",
            screensize: "",
            api_version: "DeviceInfo.getApiLevel()",
            os: "Platform.OS",
            appid: "APPID.id",
          }
        );
        console.log("helloooooooooooooo", response.data);
        setResponse(response.data);
        // Handle the response data if needed
      } catch (error) {
        console.error("Failed to fetch:", error);
        // You can add more error handling logic here if needed
      }
    };
    fetchData();
  }, []);

  createEffect(() => {
    const tedhenat = response();

    if (tedhenat) {
      console.log("keto jane ato", tedhenat);

      //@ts-ignore
      setNewToken(tedhenat.access_token);
    }
  });

  createEffect(() => {
    const fetchData = async () => {
      const tedhenat = response();
      try {
        const response = await axios.get(API_URL, {
          headers: {
            Authorization: `Bearer ${tedhenat.access_token}`,
          },
        });
        setData(response.data.playlists);
        setDeviceId(response.data.deviceid);
        console.log("responset qe dua", response.data);
        setPackageName(response?.data?.package_name);
        localStorage.setItem("licenceData", JSON.stringify(response.data));

        const licenceEndDateValue = parseISODate(
          response.data.license_end_date
        );
        const vpnLicenceEndDateValue = parseISODate(
          response.data.vpn_license_end_date
        );

        // Determine the latest end date
        const latestEndDate =
          //@ts-ignore
          licenceEndDateValue > vpnLicenceEndDateValue
            ? licenceEndDateValue
            : vpnLicenceEndDateValue;

        setLicenceEndDate(latestEndDate);
        console.log("latest end ", latestEndDate);

        setWhiteList(response.data.whitelist || false);

        // console.log(response.data);
      } catch (error) {
        console.error("Error fetching data1:", error);
      }
    };
    fetchData();
  }, [currentIndex2()]);

  const isFirst = createSelector(() => {
    return 0;
  });
  createEffect(() => {
    setIsPlaying(false);
  });
  const onCustomEnter = () => {
    navigate("home");
    setIsPlaying(true);
  };
  const onDown = () => {
    if (focusedIndex() < ITEMS_PER_PAGE - 1) {
      setFocusedIndex(focusedIndex() + 1);
    } else if (currentIndex() + ITEMS_PER_PAGE < data().length) {
      setCurrentIndex(currentIndex() + 1);
    } else {
      refreshRef.setFocus();
    }
  };
  const onUp = () => {
    if (focusedIndex() > 0) {
      setFocusedIndex(focusedIndex() - 1);
    } else if (currentIndex() > 0) {
      setCurrentIndex(currentIndex() - 1);
    }
  };
  const onRight = () => {
    deleteColumnRef.children[focusedIndex()].setFocus();
  };
  const onLeft = () => {
    playlistColumnRef.children[focusedIndex()].setFocus();
  };
  const handleDelete = (index) => {
    const newData = data().filter((_, i) => i !== index);
    setData(newData);
    setCurrentIndex(0);
    setFocusedIndex(0);
  };
  let playlistColumnRef;
  let deleteColumnRef;
  let refreshRef;

  const expiredLink = "https://orbixplay.com/";

  const changeIndex = async (newIndex) => {
    const tedhenat = data();
    if (!whiteList() && isLicenceExpired()) {
      console.log("Licence has expired. Cannot change index.");
      window.open(expiredLink, "_blank");
      return;
    }

    try {
      //@ts-ignore
      const response = await axios.get(
        `${tedhenat[newIndex].url}/player_api.php?username=${tedhenat[newIndex].username}&password=${tedhenat[newIndex].password}`
      );

      const newData = response.data;
      //@ts-ignore
      setPlayListPassword(tedhenat[newIndex].password);
      //@ts-ignore
      setPlayListUrl(tedhenat[newIndex].url);
      //@ts-ignore
      localStorage.setItem("url", tedhenat[newIndex].url);
      //@ts-ignore
      setPlayListUsername(tedhenat[newIndex].username);
      setCurrentIndex2(newIndex);
      navigate("home");
      setIsPlaying(true);
    } catch (error) {
      console.error("Fetching data failed:", error);
    }
  };
  const [playlistStatus, setPlaylistStatus] = createSignal([]);

  const checkPlaylists = async () => {
    const tedhenat = data();
    const statusArray = [];

    for (let i = 0; i < tedhenat.length; i++) {
      try {
        //@ts-ignore
        const response = await axios.get(
          `${tedhenat[i].url}/player_api.php?username=${tedhenat[i].username}&password=${tedhenat[i].password}`
        );

        if (response.status === 200) {
          //@ts-ignore
          statusArray.push(true);
        } else {
          //@ts-ignore
          statusArray.push(false);
        }
      } catch (error) {
        //@ts-ignore
        statusArray.push(false);
      }
    }
    setPlaylistStatus(statusArray);
  };

  createEffect(() => {
    checkPlaylists();
  });

  const isLicenceExpired = () => {
    // Check if the licence end date is not set
    if (!licenceEndDate()) {
      return true;
    }

    // Convert licenceEndDate to a Date object
    const endDate = new Date(licenceEndDate());

    // Ensure endDate is valid
    if (isNaN(endDate.getTime())) {
      console.error("Invalid licence end date format");
      return true; // Assuming invalid date means expired
    }

    // Get the current date
    const currentDate = new Date();

    // Compare the dates
    return endDate < currentDate;
  };

  // {isLicenceExpired() ? 'Available' : 'Not Available'}

  return (
    <View>
      <FirstPageBackground type={1} />
      <View
        width={800}
        height={1000}
        margin={20}
        backgroundColor={hexColor("#ffffff44")}
        x={100}
        y={350}
      >
        <Show when={!isLicenceExpired()}>
          <Text y={-100} style={{ color: hexColor("#a6a6a6FF") }}>
            PLAYLISTS
          </Text>
        </Show>
        <Show when={isLicenceExpired()}>
          <Text y={-200} style={{ fontSize: 40 }}>
            You don't have a Licence.
          </Text>
          <Text y={-150} x={5} style={{ fontSize: 22 }}>
            Scan to buy licence.
          </Text>
        </Show>
        <Show when={data().length > 0 && !(isLicenceExpired())} fallback={<FallbackView />}>
          <View>
            <Column
              ref={(el) => {
                playlistColumnRef = el;
              }}
              onDown={onDown}
              onUp={onUp}
              onRight={onRight}
            >
              <For
                each={data().slice(
                  currentIndex(),
                  currentIndex() + ITEMS_PER_PAGE
                )}
              >
                {(playlist, i) => (
                  <View
                    style={{
                      ...styles.LicenseButton,
                    }}

                    width={790}
                    height={95}
                    onEnter={() => {
                      changeIndex(i() + currentIndex());
                    }}
                    autofocus={i() === focusedIndex()}
                    key={playlist.id}
                    borderRadius={20}
                  >
                    <View width={700}
                      height={50}>
                      <Text>
                        {playlist.playlistName}{" "}
                      </Text>
                    </View>

                    <View
                      width={30}
                      height={30}
                      color={playlistStatus()[i() + currentIndex()] ? hexColor('#00ff00') : hexColor('#ff0000')}
                      borderRadius={15}
                    />
                  </View>


                )}
              </For>
            </Column>
            <Row
              ref={(el) => {
                refreshRef = el;
              }}
              onUp={() => playlistColumnRef.setFocus()}
            >
              <View
                style={styles.LicenseButton}
                y={450}
                height={100}
                borderRadius={20}
                width={400}
              >
                <View width={50} height={50} src="https://i.ibb.co/xmPFS2y/refresh-page-option.png" />
              </View>
            </Row>
            <View
              x={810}
              ref={(el) => {
                deleteColumnRef = el;
              }}
              onLeft={onLeft}
              style={{ display: "flex", flexDirection: "column" }}
              gap={21}
            // onDown={()=> deleteColumnRef.children.setFocus()}
            >
              <For
                each={data().slice(
                  currentIndex(),
                  currentIndex() + ITEMS_PER_PAGE
                )}
              >
                {(playlist, i) => (
                  <View
                    style={styles.LicenseButton}
                    onEnter={() => handleDelete(i() + currentIndex())}
                    width={100}
                    height={95}
                    borderRadius={20}
                    //@ts-ignore
                    key={`${playlist.id}`}
                  >
                    <View height={50} width={50} src="https://i.ibb.co/w690c1G/recycle-bin.png"></View>
                  </View>
                )}
              </For>
            </View>
          </View>
        </Show>
        <View>
          <Text y={580}>License</Text>
          <Text y={620} style={{ fontSize: 20 }}>
            {isLicenceExpired() ? "Expired" : "Not Expired"}, {packageName()}
          </Text>
          <Show when={licenceEndDate()}>
            <Text y={660} style={{ fontSize: 20 }}>
              Expires , {`${licenceEndDate()}`}
            </Text>
          </Show>
        </View>
      </View>
      <View
        width={500}
        height={500}
        margin={20}
        backgroundColor={hexColor("#ffffff44")}
        x={1200}
        y={300}
      >
        <View style={{ display: "flex", justifyContent: "center" }}>


          <Show when={!isLicenceExpired()}>
            <Text y={-100} style={{ color: hexColor("#a6a6a6FF") }}>
              SCAN TO ADD PLAYLIST
            </Text>
          </Show>
          <Show when={isLicenceExpired()}>
            <Text y={-100} style={{ color: hexColor("#a6a6a6FF") }}>
              SCAN TO BUY LICENCE
            </Text>
          </Show>

        </View>
        <Column>

          <Show when={newToken()}>





            <Show when={!isLicenceExpired()}>
              <QrCode api_token={newToken()} />
            </Show>
            <Show when={isLicenceExpired()}>
              <QrCodeWithAxios api_token={newToken()} />
            </Show>



          </Show>

          <Show when={data()}>
            <View
              display="flex"
              flexDirection="column"
              alignItems="center"
              y={520}
              gap={15}
            >


              <Show when={!isLicenceExpired()}>
                <Text style={{ color: hexColor("#a6a6a6FF") }}>or visit</Text>
                <Text>
                  https://orbixplay.com/mylist and enter this device id:
                </Text>
              </Show>


              <Text style={{ color: hexColor("#FFA500FF") }}>{deviceId()}</Text>
              {/* <Text style={{ fontSize: 18, color: hexColor("#808080FF") }}>
                Version 0.35
              </Text> */}
            </View>
          </Show>
        </Column>
      </View>
    </View>
  );
};
export default FirstPage;
