import { hexColor, View } from "@lightningjs/solid";
import { createSignal, onMount, Show } from "solid-js";

const QrCode = (props) => {
  const API_URL = "https://deviceapi.orbixplay.com/app/live-lbgjx/endpoint/getqrcodeplaylistv2"
  // const API_TOKEN = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJiYWFzX2RldmljZV9pZCI6IjAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMCIsImJhYXNfZG9tYWluX2lkIjoiNjU0NDFiZjQ1OWRjYzYyNWI1YjY4ZGJlIiwiZXhwIjoxNzE5OTI2MTU3LCJpYXQiOjE3MTk5MjQzNTcsImlzcyI6IjY2ODNmNjg1YjMzMTI3MGU5MGIxZjc2ZiIsImp0aSI6IjY2ODNmNjg1YjMzMTI3MGU5MGIxZjc3MSIsInN0aXRjaF9kZXZJZCI6IjAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMCIsInN0aXRjaF9kb21haW5JZCI6IjY1NDQxYmY0NTlkY2M2MjViNWI2OGRiZSIsInN1YiI6IjY2NjMwZDYwMDdhNTE4OGU0OGQ4OTgyZCIsInR5cCI6ImFjY2VzcyIsInVzZXJfZGF0YSI6eyJfaWQiOiI2NjYzMGQ2MDA3YTUxODhlNDhkODk4MmUiLCJtb25nb191c2VyX2lkIjoiNjY2MzBkNjAwN2E1MTg4ZTQ4ZDg5ODJkIiwiZGV2aWNlaWQiOiI4QToyQzoxNjpGMTpBODpBMCIsImNyZWF0ZWRfYXQiOnsiJGRhdGUiOnsiJG51bWJlckxvbmciOiIxNzE3NzY3NTIwNTI5In19LCJwbGF5bGlzdHMiOltdLCJsaWNlbnNlX2VuZF9kYXRlIjp7IiRkYXRlIjp7IiRudW1iZXJMb25nIjoiMTcxODg3NjEwMDAwMCJ9fSwidnBuX2xpY2Vuc2VfZW5kX2RhdGUiOnsiJGRhdGUiOnsiJG51bWJlckxvbmciOiIxNzE4ODc2MTAwMDAwIn19LCJwYWNrYWdlX25hbWUiOiJQcmVtaXVtIiwiaWQiOiI2NjYzMGQ2MDA3YTUxODhlNDhkODk4MmUifX0.O_-L4rtTOhv2gQbZHIZ2BLEmhGIKiNwG7rfolhMrpyM"
  const [imageUrl, setImageUrl] = createSignal("");
  // Function to update the image URL
  const updateImageUrl = (newUrl) => {
    setImageUrl(newUrl);
  };
  // Function to fetch the image URL from the API
  const fetchImageUrl = async () => {
    try {
      const token = props.api_token;
      const apiUrl = API_URL;
      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Failed to fetch image URL");
      }
      const data = await response.json();
      // console.log("data",data.qr_playlist_url);
      updateImageUrl(data.qr_playlist_url);
    } catch (error) {
      console.error("Error fetching image URL:", error);
    }
  };
  // Fetch the image URL when the component mounts
  // onCleanup(() => {
  // Clean up any resources if needed
  // });
  fetchImageUrl();
  return (
    <Show when={imageUrl()}>
        <div>
          <img src={imageUrl()} alt="Add Playlist" />
        </div>

    </Show>
  );
}
export default QrCode;