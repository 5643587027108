// Documentation from Shaka player:
// https://shaka-player-demo.appspot.com/docs/api/tutorial-basic-usage.html
import { createEffect, on, createSignal } from "solid-js";
import {
  globalStream,
  globalStreamType,
  playListPassword,
  playListUrl,
  playListUsername,
} from "./state";

let manifestUri = "";

//http://ilmiodog.ddns.net/series/PIN2_Donato3/Maceraamico4/362902.mp4

//http://ilmiodog.ddns.net/PIN2_Donato3/Maceraamico4/289

function initApp() {
  // Install built-in polyfills to patch browser incompatibilities.
  // shaka.polyfill.installAll();

  // Check to see if the browser supports the basic APIs Shaka needs.
  // if (shaka.Player.isBrowserSupported()) {
  // Everything looks good!
  initPlayer();

  // } else {
  // This browser does not have the minimum set of APIs we need.
  // console.error("Browser not supported!");
  // }
}

async function initPlayer() {
  // const [localStream, setLocalStream] = createSignal<string>("");

  // createEffect(
  //   on(
  //     globalStream,
  //     (img) => {
  //       // setLocalStream(img);
  //       manifestUri = `http://ilmiodog.ddns.net/series/PIN2_Donato3/Maceraamico4/${img}.mp4`;
  //     },
  //     { defer: true }
  //   )
  // );

  // Create a Player instance.
  const video = document.getElementById("video");
  // const player = new shaka.Player();
  // await player.attach(video);

  // const manifestUri =

  // Attach player to the window to make it easy to access in the JS console.
  // window.player = player;

  // Listen for error events.
  // player.addEventListener("error", onErrorEvent);

  createEffect(
    on(
      [globalStream, globalStreamType],
      ([str, type]) => {
        const videoElement = document.querySelector("#video");
        if (type === "live") {
          console.log("kevkkkee", str);
          manifestUri = `${playListUrl()}/live/${playListUsername()}/${playListPassword()}/${str}.ts`;
          videoElement.removeAttribute("controls");
        } else {
          manifestUri = `${playListUrl()}/${type}/${playListUsername()}/${playListPassword()}/${str}.mp4`;

          // videoElement.setAttribute("controls", "");
        }
        document.querySelector("#video > source").src = manifestUri;
        video.load(); // Load the new video source
        console.log("The video source has been updated!");
        console.log("cfare type eshte", type);
      },
      { defer: true }
    )
  );
}
const [response, setResponse] = createSignal(false);

let seekBarTimeout;

function addKeyListeners() {
  document.addEventListener("keydown", (event) => {
    const video = document.getElementById("video");
    const seekBar = document.getElementById("custom-seek-bar");
    const seekBarElement = document.getElementById("seek-bar");
    const thumb = document.getElementById("thumb");

    if (globalStreamType() === "live") {
      return; // Exit early if live stream
    }

    if (response === false) {
      return true;
    }

    if (video) {
      const isPlaying = !video.paused;
      const seekBarVisible = !seekBar.classList.contains("hidden");

      if (isPlaying) {
        if (!seekBarVisible) {
          seekBar.classList.remove("hidden");
          resetSeekBarAutoHide();
        }

        const seekBarWidth = seekBarElement.clientWidth;
        const thumbWidth = thumb.clientWidth;

        let currentPosition = parseFloat(thumb.style.left) || 0;

        if (event.key === "ArrowRight") {
          currentPosition += 1; // Move right by 1%
        } else if (event.key === "ArrowLeft") {
          currentPosition -= 1; // Move left by 1%
        }

        currentPosition = Math.max(0, Math.min(currentPosition, 100));
        thumb.style.left = `${currentPosition}%`;

        if (isFinite(video.duration) && video.duration > 0) {
          const newTime = (currentPosition / 100) * video.duration;

          if (isFinite(newTime)) {
            video.currentTime = newTime;
          } else {
            console.error("Computed newTime is not finite.");
          }
        } else {
          console.error("Video duration is not valid or video is not loaded.");
        }
      }
    }
  });
}

function setupSeekBarAutoHide() {
  const seekBar = document.getElementById("custom-seek-bar");

  if (seekBar) {
    seekBar.classList.add("hidden");
  }
}

function resetSeekBarAutoHide() {
  clearTimeout(seekBarTimeout); // Clear any existing timeout

  seekBarTimeout = setTimeout(() => {
    const seekBar = document.getElementById("custom-seek-bar");
    if (seekBar) {
      seekBar.classList.add("hidden");
    }
  }, 5000); // Hide after 5 seconds
}

function onErrorEvent(event) {
  // Extract the shaka.util.Error object from the event.
  onError(event.detail);
}

function onError(error) {
  // Log the error.
  console.error("Error code", error.code, "object", error);
}

export function playVideo() {
  const video = document.getElementById("video");
  const seekBar = document.getElementById("custom-seek-bar");

  setResponse(true);

  video.hidden = false;
  setTimeout(() => video.play(), 50);
  video.focus();

  // Show the seek bar when the video starts playing
  if (seekBar) {
    seekBar.classList.remove("hidden");
    resetSeekBarAutoHide(); // Reset the timeout to hide the seek bar
  }

  return video;
}

export function closeVideo() {
  const video = document.getElementById("video");
  const seekBar = document.getElementById("custom-seek-bar");
  setResponse(false);

  video.hidden = true;
  video.pause();

  // Hide the seek bar when the video is closed
  if (seekBar) {
    seekBar.classList.add("hidden");
  }
  return video;
}

document.addEventListener("DOMContentLoaded", () => {
  initApp();
  addKeyListeners();
  setupSeekBarAutoHide();
});
