import {
  createEffect,
  createMemo,
  on,
  createSignal,
  //@ts-ignore
  Show,
  createSelector,
  //@ts-ignore
  For,
  onMount,
  JSX,
  onCleanup,
} from "solid-js";
import {
  ElementNode,
  IntrinsicTextNodeStyleProps,
  View,
  //@ts-ignore
  activeElement,
  Text,
  hexColor,
} from "@lightningjs/solid";
import { Column, Button, Row, ButtonContainer } from "@lightningjs/solid-ui";
import { useNavigate, useParams } from "@solidjs/router";
import { TileRow, TileRowMovie } from "../../components";
import styles from "../../styles";
import {
  playListPassword,
  playListUrl,
  playListUsername,
  setGlobalBackground,
  setGlobalStream,
  setGlobalStreamType,
} from "../../state";
import browseProvider from "../../api/providers/browse";
import channelsProvider from "../../api/providers/channels";
import { createInfiniteScroll } from "../../components/pagination";
import ContentBlock from "../../components/ContentBlock";
import { assertTruthy } from "@lightningjs/renderer/utils";
import { debounce } from "@solid-primitives/scheduled";
import axios from "axios";
import { playVideo } from "../video";
import FirstPageBackground from "../../components/FirstPageBackground";
import channelsCategories from "../../api/providers/channelsCategories";

const RowStyles: any = {
  display: "flex",
  justifyContent: "flexStart",
  width: 600,
  height: 900,
  // color: hexColor("00000000"),
  gap: 26,
  y: 400,
} satisfies IntrinsicTextNodeStyleProps;

const ChannelMenu = () => {
  const params = useParams();
  const [columnY, setcolumnY] = createSignal(0);
  const [isPlaying, setIsPlaying] = createSignal(false);
  const [heroContent, setHeroContent] = createSignal({});
  const navigate = useNavigate();

  ////// Search////////////////////////////////////////////
  const [inputValue, setInputValue] = createSignal("");
  const [isFocused, setIsFocused] = createSignal(false);
  const [isKeyboardFocused, setIsKeyboardFocused] = createSignal(false);
  // const [cursorPos, setCursorPos] = createSignal(0);
  const [isSearchSubmitted, setIsSearchSubmitted] = createSignal(false);
  ////// Search////////////////////////////////////////////

  const isFirst = createSelector(() => {
    if (!isKeyboardFocused()) {
      return 0;
    }
  });
  const [data, setData] = createSignal(null);
  const [data2, setData2] = createSignal(null);
  const [channelNumber, setChannelNumber] = createSignal(0);
  const [dataCategories, setDataCategories] = createSignal(null);
  const [number, setNumber] = createSignal("501"); // Default number is 1
  let cachedDataCategories = null;
  const [isNumberChanging, setIsNumberChanging] = createSignal(false);


  let timeoutId: ReturnType<typeof setTimeout> | null = null;

  const handleNumberChange = (newNumber: any) => {


    if (number() === newNumber) {
      return;
    }


    // Clear the previous timeout if it exists
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    setIsNumberChanging(true);
    // Set a new timeout
    timeoutId = setTimeout(() => {
      setNumber(newNumber);
      setIsNumberChanging(false);
      timeoutId = null; // Reset the timeout ID
    }, 500); // Adjust the delay as needed
  };

  const provider = createMemo(() => {
    return createInfiniteScroll(
      channelsProvider(params.filter || "all", number())
    );
  });

  const providerCategory = createMemo(() => {
    return createInfiniteScroll(channelsCategories(params.filter || "all"));
  });

  createEffect(() => {
    const username = playListUsername();
    const password = playListPassword();
    const playList = playListUrl();

    const kevi = providerCategory().pages();

    if (providerCategory().pages()) {
      // console.log('kevikevikevikevi', kevi);
      //@ts-ignore
      setDataCategories(kevi);
    }

    // console.log('username i marre ', username);

    // axios.get(`${playList}/player_api.php?username=${username}&password=${password}&action=get_live_categories`)
    //     .then(response => setDataCategories(response.data))
    //     .catch(error => console.error(error));
  });

  const delayedBackgrounds = debounce(
    (img: string) => setGlobalBackground("#0A1C34"),
    400
  );

  const delayedHero = debounce(
    (content: {}) => setHeroContent(content || {}),
    200
  );

  onMount(() => {
    setGlobalBackground("#0A1C34");
  });

  createEffect(
    on(
      activeElement,
      (elm: any) => {
        if (elm.backdrop) {
          delayedBackgrounds(elm.backdrop);
        }

        if (elm.heroContent) {
          delayedHero(elm.heroContent);
        }
      },
      { defer: true }
    )
  );

  function onRowFocus(this: any) {
    this.children.selected?.setFocus();
    setcolumnY((this.y || 0) * -1 + 24);
    let numPages = provider().pages().length;

    if (
      numPages === 0 ||
      (this.parent.selected && this.parent.selected >= numPages - 2)
    ) {
      provider().setPage((p) => p + 1);
    }
  }

  function onEnter(this: ElementNode) {
    //@ts-ignore
    let entity = this.children.selected;
    // setIsPlaying(true)
    //@ts-ignore
    //@ts-ignore
    // setChannelNumber(entity.data.num);
    setGlobalStreamType("live");

    // console.log('hahah', entity);

    assertTruthy(entity && entity.href);
    // navigate(entity.href);
    // navigate('/livetv')
    //@ts-ignore
    navigate(`/livetv?num=${entity.data.stream_id}`);
    // setGlobalStream("289")

    // setGlobalStreamType('live');

    // return true;
  }

  onMount(() => {
    setGlobalBackground("#0A1C34");

    const storedData = localStorage.getItem("channelData");

    if (storedData) {
      // console.log("Loaded data from localStorage", JSON.parse(storedData));
      setData2(JSON.parse(storedData)); // Load data from localStorage
    }
  });

  createEffect(() => {
    const username = playListUsername();
    const password = playListPassword();
    const playList = playListUrl();

    axios
      .get(
        `${playList}/player_api.php?username=${username}&password=${password}&action=get_live_streams`
      )
      .then((response) => {
        // console.log("Fetched data from API", response.data); // Log fetched data
        setData(response.data);
        localStorage.setItem("channelData", JSON.stringify(response.data)); // Store data in localStorage
      })
      .catch((error) => console.error(error));
    // console.log('dataaaaaaaaa', data());
  });

  const incrementValue = () => {
    const isPlying = isPlaying();
    if (isPlying) {
      const tedhenat = data();
      setChannelNumber((prevValue) => prevValue + 1);
      const numri = channelNumber();
      // console.log('te dhenat per epg', tedhenat);
      //@ts-ignore
      // console.log('indeksi', tedhenat[numri - 1].stream_id);
      //@ts-ignore
      setGlobalStream(tedhenat[numri - 1].stream_id);
    } else {
      console.log("not playing");
    }
  };

  ///Search////////////////////////////////////////

  createEffect(() => {
    localStorage.setItem("searchInput", inputValue());
  });

  onMount(() => {
    const storedInput = localStorage.getItem("searchInput");
    if (storedInput) {
      setInputValue(storedInput);
    }
  });

  const filterData = () => {
    if (!inputValue()) return data2();
    return data2().filter((item: any) =>
      item.name.toLowerCase().includes(inputValue().toLowerCase())
    );
  };

  let myButton;
  let viewRef;
  let search;
  let keyboard;

  const handleClick = (char) => {
    setInputValue(inputValue() + char);
    console.log(char);
  };

  const handleSpace = () => {
    setInputValue(inputValue() + " ");
  };

  const handleBackspace = () => {
    setInputValue(inputValue().slice(0, -1));
    setIsSearchSubmitted(false);
    //   backspace.setFocus()
    setIsKeyboardFocused(true);
    // if (inputValue() === '') {
    //     //     setIsKeyboardFocused(false);
    //     //     myButton.setFocus();
    //     search.setFocus()
    // }
  };

  const handleEnter = () => {
    // console.log(e.key);

    //   setInputValue(inputValue() + "\n");
    if (inputValue() !== "") {
      setIsSearchSubmitted(true);
      setIsKeyboardFocused(false);
      search.setFocus();
    }
  };

  let row1;
  let row2;
  let row3;
  let row4;
  let row5;
  let backspace;

  /////////Search///////////////////////////////

  return (
    <>
      <FirstPageBackground />

      <Show when={isKeyboardFocused()}>

        <View
          y={390}
          width={1920}
          onEscape={() => {
            search.setFocus();
            setIsKeyboardFocused(false);
          }}
          height={700}
          positionAbsolute={0}
          color={hexColor("#0A1C34")}
        >
          {/* <View class="output">{inputValue()}</View> */}
          <View y={35} x={300} ref={keyboard}>
            <Row
              ref={row1}
              onDown={() => row2.setFocus()}
              onUp={() => search.setFocus()}
              class="keyboard"
              display="flex"
              flexDirection="row"
              gap={7}
            >
              <Button
                height={120}
                width={120}
                class="key"
                onEnter={() => handleClick("0")}
              >
                0
              </Button>
              <Button
                height={120}
                width={120}
                class="key"
                onEnter={() => handleClick("1")}
              >
                1
              </Button>
              <Button
                height={120}
                width={120}
                class="key"
                onEnter={() => handleClick("2")}
              >
                2
              </Button>
              <Button
                height={120}
                width={120}
                class="key"
                onEnter={() => handleClick("3")}
              >
                3
              </Button>
              <Button
                height={120}
                width={120}
                class="key"
                onEnter={() => handleClick("1")}
              >
                4
              </Button>
              <Button
                height={120}
                width={120}
                class="key"
                onEnter={() => handleClick("2")}
              >
                5
              </Button>
              <Button
                height={120}
                width={120}
                class="key"
                onEnter={() => handleClick("3")}
              >
                6
              </Button>
              <Button
                height={120}
                width={120}
                class="key"
                onEnter={() => handleClick("1")}
              >
                7
              </Button>
              <Button
                height={120}
                width={120}
                class="key"
                onEnter={() => handleClick("2")}
              >
                8
              </Button>
              <Button
                height={120}
                width={120}
                class="key"
                onEnter={() => handleClick("3")}
              >
                9
              </Button>
            </Row>
            <Row
              ref={row2}
              onUp={() => row1.setFocus()}
              onDown={() => row3.setFocus()}
              class="keyboard"
              display="flex"
              flexDirection="row"
              gap={7}
              y={127}
            >
              <Button
                height={120}
                width={120}
                class="key"
                onEnter={() => handleClick("q")}
              >
                q
              </Button>
              <Button
                height={120}
                width={120}
                class="key"
                onEnter={() => handleClick("w")}
              >
                w
              </Button>
              <Button
                height={120}
                width={120}
                class="key"
                onEnter={() => handleClick("e")}
              >
                e
              </Button>
              <Button
                height={120}
                width={120}
                class="key"
                onEnter={() => handleClick("r")}
              >
                r
              </Button>
              <Button
                height={120}
                width={120}
                class="key"
                onEnter={() => handleClick("t")}
              >
                t
              </Button>
              <Button
                height={120}
                width={120}
                class="key"
                onEnter={() => handleClick("y")}
              >
                y
              </Button>
              <Button
                height={120}
                width={120}
                class="key"
                onEnter={() => handleClick("u")}
              >
                u
              </Button>
              <Button
                height={120}
                width={120}
                class="key"
                onEnter={() => handleClick("i")}
              >
                i
              </Button>
              <Button
                height={120}
                width={120}
                class="key"
                onEnter={() => handleClick("o")}
              >
                o
              </Button>
              <Button
                height={120}
                width={120}
                class="key"
                onEnter={() => handleClick("p")}
              >
                p
              </Button>
            </Row>
            <Row
              ref={row3}
              onUp={() => row2.setFocus()}
              onDown={() => row4.setFocus()}
              class="keyboard"
              display="flex"
              flexDirection="row"
              gap={7}
              y={254}
            >
              <Button
                height={120}
                width={120}
                class="key"
                onEnter={() => handleClick("a")}
              >
                a
              </Button>
              <Button
                height={120}
                width={120}
                class="key"
                onEnter={() => handleClick("s")}
              >
                s
              </Button>
              <Button
                height={120}
                width={120}
                class="key"
                onEnter={() => handleClick("d")}
              >
                d
              </Button>
              <Button
                height={120}
                width={120}
                class="key"
                onEnter={() => handleClick("f")}
              >
                f
              </Button>
              <Button
                height={120}
                width={120}
                class="key"
                onEnter={() => handleClick("g")}
              >
                g
              </Button>
              <Button
                height={120}
                width={120}
                class="key"
                onEnter={() => handleClick("h")}
              >
                h
              </Button>
              <Button
                height={120}
                width={120}
                class="key"
                onEnter={() => handleClick("j")}
              >
                j
              </Button>
              <Button
                height={120}
                width={120}
                class="key"
                onEnter={() => handleClick("k")}
              >
                k
              </Button>
              <Button
                height={120}
                width={120}
                class="key"
                onEnter={() => handleClick("l")}
              >
                l
              </Button>
              <Button
                height={120}
                width={120}
                class="key"
                onEnter={handleEnter}
              >
                Enter
              </Button>
            </Row>
            <Row
              ref={row4}
              onUp={() => row3.setFocus()}
              onDown={() => row5.setFocus()}
              class="keyboard"
              display="flex"
              flexDirection="row"
              gap={7}
              y={381}
            >
              <Button
                height={120}
                width={120}
                class="key"
                onEnter={() => handleClick("z")}
              >
                z
              </Button>
              <Button
                height={120}
                width={120}
                class="key"
                onEnter={() => handleClick("x")}
              >
                x
              </Button>
              <Button
                height={120}
                width={120}
                class="key"
                onEnter={() => handleClick("c")}
              >
                c
              </Button>
              <Button
                height={120}
                width={120}
                class="key"
                onEnter={() => handleClick("v")}
              >
                v
              </Button>
              <Button
                height={120}
                width={120}
                class="key"
                onEnter={() => handleClick("b")}
              >
                b
              </Button>
              <Button
                height={120}
                width={120}
                class="key"
                onEnter={() => handleClick("n")}
              >
                n
              </Button>
              <Button
                height={120}
                width={120}
                class="key"
                onEnter={() => handleClick("m")}
              >
                m
              </Button>
              <Button
                height={120}
                width={374}
                class="key"
                onEnter={handleBackspace}
                ref={backspace}
              >
                Backspace
              </Button>
            </Row>
            <Row
              ref={row5}
              onUp={() => row4.setFocus()}
              class="keyboard"
              display="flex"
              flexDirection="row"
              gap={120}
              y={508}
            >
              <Button
                height={120}
                width={1263}
                class="key"
                onEnter={handleSpace}
              >
                Space
              </Button>
            </Row>
          </View>
        </View>
      </Show>
      <View y={20} onEscape={() => navigate("/home")}>
        <View x={790} y={30} width={800} height={80}>
          <View
            // tabIndex={0} // Makes the div focusable
            onFocus={() => setIsFocused(true)}
            onBlur={() => {
              setIsFocused(false);
            }}
            onEnter={() => {
              setIsKeyboardFocused(true);
            }}
          >
            <ButtonContainer
              ref={search}
              onLeft={() => {
                isKeyboardFocused() ? row1.setFocus() : myButton.setFocus();
              }}
              onUp={() => isKeyboardFocused() && row1.setFocus()}
              onRight={() => isKeyboardFocused() && row1.setFocus()}
              onDown={() => {
                isKeyboardFocused() ? row1.setFocus() : viewRef.setFocus();
              }}
              style={[{ height: 90, color: 0x4F7F4F }, styles.ThumbnailChannel]}
            >
              {!inputValue() ? <Text>Search:</Text> : <Text></Text>}
              <Text x={110}>
                {inputValue()}
              </Text>
            </ButtonContainer>

          </View>
        </View>
        <View y={150}>

          <Show
            when={providerCategory().pages() && providerCategory().pages().length > 0}
            fallback={<View x={200} y={500}><Text>Loading ....</Text></View>}
          >

            <Show when={!isSearchSubmitted() || inputValue() === ""}>
              <View
                onUp={() => search.setFocus()}
                onRight={() => viewRef.setFocus()}
              >
                <Column
                  ref={myButton}
                  x={160}
                  transition={{ y: { duration: 350 } }}
                  gap={10}
                  style={RowStyles}
                  y={30}
                >
                  <For each={providerCategory().pages()}>
                    {(categorie, i) => (
                      <Button
                        style={{
                          borderRadius: 15,
                          color: 0x4F7F4F,
                          focus: { color: 0x467ffa },
                          transition: {
                            scale: { duration: 250, easing: "ease-in-out" },
                          },
                          height: 105,
                        }}
                        autofocus={isFirst(i())}
                        index={i()}
                        states={"active"}
                        onFocus={() => {
                          handleNumberChange(categorie.categoryID);
                          console.log("kevi", categorie.categoryID);
                        }}
                      >
                        {categorie.category_name.length > 22
                          ? `${categorie.category_name.substring(0, 22)}...`
                          : categorie.category_name}
                      </Button>
                    )}
                  </For>
                </Column>
              </View>
            </Show>
            <View
              onUp={() => {
                search.setFocus();
              }}
              x={620}
              onEscape={incrementValue}
              onLeft={() => {
                inputValue() === "" && myButton.setFocus();
                // return true;
              }}
              clipping
              style={styles.itemsContainerChannelMenu}
            >
              <Column
                plinko
                announce="TV Channels"
                y={columnY()}
                scroll="none"
                style={styles.Column}
                ref={viewRef}
              >
                <Show
                  when={isSearchSubmitted() && inputValue() !== ""}
                  fallback={
                    <>
                      <Show
                        when={provider().pages() && provider().pages().length > 0 && !isNumberChanging()}
                        fallback={<View x={200} y={500}><Text>Loading ....</Text></View>}
                      >

                        <For each={provider().pages()}>
                          {(items, i) => (
                            <TileRow
                              items={items}
                              width={1620}
                              onFocus={onRowFocus}
                              onEnter={onEnter}
                            />
                          )}
                        </For>
                      </Show>
                    </>
                  }
                >
                  <For each={filterData()}>
                    {(item, i) => (
                      // <Row style={styles.Row}>
                      <View
                        onFocus={onRowFocus}
                        style={styles.ThumbnailChannel}
                        onEnter={() => navigate(`/livetv?num=${item.stream_id}`)}
                      >
                        <View
                          x={20}
                          y={10}
                          src={item.stream_icon}
                          style={{ width: 120, height: 100 }}
                        ></View>
                        <Text x={300} y={40}>
                          {item.num}, {item.name.length > 13 ? `${item.name.substring(0, 13)}...` : item.name}
                        </Text>

                      </View>
                      // </Row>
                    )}
                  </For>
                </Show>
              </Column>
            </View>
          </Show>
        </View>
      </View>
    </>
  );
};

export default ChannelMenu;
