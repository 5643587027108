//@ts-ignore
import { createEffect, createSignal, onCleanup, For, onMount, createMemo } from "solid-js";
import { View, Text, hexColor, Show, ElementNode } from "@lightningjs/solid";
import { useNavigate, useParams } from "@solidjs/router";
import FirstPageBackground from "../../components/FirstPageBackground";
import styles from "../../styles";
import { isVpnMode, playListPassword, playListUrl, playListUsername, setGlobalBackground, setIsPlaying, setIsVpnMode } from "../../state";
import { Button, ButtonContainer, Column, Row } from "@lightningjs/solid-ui";
import Background from "../../components/Background";
import axios from "axios";
import { assertTruthy } from "@lightningjs/renderer/utils";
import { createInfiniteScroll } from "../../components/pagination";
import searchMovies from "../../api/providers/searchMovies";





const SearchPage = () => {
    const params = useParams();
    const navigate = useNavigate();

    ////// Search////////////////////////////////////////////
    const [inputValue, setInputValue] = createSignal("");
    const [isFocused, setIsFocused] = createSignal(false);
    const [isKeyboardFocused, setIsKeyboardFocused] = createSignal(false);
    // const [cursorPos, setCursorPos] = createSignal(0);
    const [isSearchSubmitted, setIsSearchSubmitted] = createSignal(false);
    const [data2, setData2] = createSignal(null);
    const [data, setData] = createSignal(null);


    ////// Search////////////////////////////////////////////
    const providerCategory = createMemo(() => {
        return createInfiniteScroll(
            //@ts-ignore
            searchMovies(params.filter || "all")
        );
    });


    createEffect(() => {
        const kevi = providerCategory().pages();
        if (providerCategory().pages()) {
            //@ts-ignore
            console.log('keviii', kevi);
            setData(kevi)


        }
    });




    onMount(() => {
        setGlobalBackground("#0A1C34");

        const storedData = localStorage.getItem("moviesData");

        if (storedData) {
            // console.log("Loaded data from localStorage", JSON.parse(storedData));
            setData2(JSON.parse(storedData)); // Load data from localStorage
        }
    });

    ///Search////////////////////////////////////////

    createEffect(() => {
        localStorage.setItem("searchInput", inputValue());
    });

    onMount(() => {
        const storedInput = localStorage.getItem("searchInput");
        if (storedInput) {
            setInputValue(storedInput);
        }
    });

    const [displayedItems, setDisplayedItems] = createSignal([]);

    const filterData = () => {
        if (!inputValue()) return providerCategory().pages();
        return providerCategory().pages().filter((item: any) =>
            item.title.toLowerCase().includes(inputValue().toLowerCase())
        );
    };

    // Create an effect to update the displayed items whenever `filterData` changes
    createEffect(() => {
        const filtered = filterData();
        // Optionally, you can reset the list to empty before setting new data
        setDisplayedItems([]); // Reset list
        setTimeout(() => setDisplayedItems(filtered), 0); // Update list with new data
    });

    let myButton;
    let viewRef;
    let search;
    let keyboard;

    const handleClick = (char) => {
        setInputValue(inputValue() + char);
        console.log(char);
    };

    const handleSpace = () => {
        setInputValue(inputValue() + " ");
    };

    const handleBackspace = () => {
        setInputValue(inputValue().slice(0, -1));
        setIsSearchSubmitted(false);
        //   backspace.setFocus()
        setIsKeyboardFocused(true);
        // if (inputValue() === '') {
        //     //     setIsKeyboardFocused(false);
        //     //     myButton.setFocus();
        //     search.setFocus()
        // }
    };

    const handleEnter = () => {
        // console.log(e.key);

        //   setInputValue(inputValue() + "\n");
        if (inputValue() !== "") {
            setIsSearchSubmitted(true);
            setIsKeyboardFocused(false);
            search.setFocus();
        }
    };

    let row1;
    let row2;
    let row3;
    let row4;
    let row5;
    let backspace;

    /////////Search///////////////////////////////

    // createEffect(() => {
    //     const username = playListUsername();
    //     const password = playListPassword();
    //     const playList = playListUrl();

    //     axios
    //         .get(
    //             `${playList}/player_api.php?username=${username}&password=${password}&action=get_vod_streams`
    //         )
    //         .then((response) => {
    //             const limitedData = response.data.slice(0, 100); // Limit to 5000 items
    //             setData(JSON.stringify(limitedData));
    //             console.log('Number of movies fetched:', limitedData.length);

    //             localStorage.setItem("moviesData", JSON.stringify(limitedData)); // Store limited data in localStorage
    //         })
    //         .catch((error) => console.error('Error fetching data:', error));

    //     // console.log('dataaaaaaaaa', data());
    // });


    function onEnter(categoriid: any, streamid: any) {
        console.log('keviiiiiii', categoriid, streamid);


        navigate(`/entity/movie/${streamid}/${categoriid}`);

        return true;
    }



    return (
        <View
            onEscape={() => {
                navigate(-1);
            }}
        >

            <FirstPageBackground />
            <View
                //   y={390}
                width={1920}
                onEscape={() => {
                    search.setFocus();
                    setIsKeyboardFocused(false);
                }}
                height={1700}
                positionAbsolute={0}
                color={hexColor("#0A1C34")}
            >
                {/* <View class="output">{inputValue()}</View> */}
                <View y={235} x={50} ref={keyboard}>
                    <Row
                        ref={row1}
                        autofocus
                        onDown={() => row2.setFocus()}
                        // onUp={() => search.setFocus()}
                        class="keyboard"
                        display="flex"
                        flexDirection="row"
                        gap={7}
                        onRight={() => viewRef.setFocus()}

                    >
                        <Button
                            height={120}
                            width={120}
                            class="key"
                            onEnter={() => handleClick("0")}
                        >
                            0
                        </Button>
                        <Button
                            height={120}
                            width={120}
                            class="key"
                            onEnter={() => handleClick("1")}
                        >
                            1
                        </Button>
                        <Button
                            height={120}
                            width={120}
                            class="key"
                            onEnter={() => handleClick("2")}
                        >
                            2
                        </Button>
                        <Button
                            height={120}
                            width={120}
                            class="key"
                            onEnter={() => handleClick("3")}
                        >
                            3
                        </Button>
                        <Button
                            height={120}
                            width={120}
                            class="key"
                            onEnter={() => handleClick("1")}
                        >
                            4
                        </Button>
                        <Button
                            height={120}
                            width={120}
                            class="key"
                            onEnter={() => handleClick("2")}
                        >
                            5
                        </Button>
                        <Button
                            height={120}
                            width={120}
                            class="key"
                            onEnter={() => handleClick("3")}
                        >
                            6
                        </Button>
                        <Button
                            height={120}
                            width={120}
                            class="key"
                            onEnter={() => handleClick("1")}
                        >
                            7
                        </Button>
                        <Button
                            height={120}
                            width={120}
                            class="key"
                            onEnter={() => handleClick("2")}
                        >
                            8
                        </Button>
                        <Button
                            height={120}
                            width={120}
                            class="key"
                            onEnter={() => handleClick("3")}
                        >
                            9
                        </Button>
                    </Row>
                    <Row
                        ref={row2}
                        onUp={() => row1.setFocus()}
                        onDown={() => row3.setFocus()}
                        class="keyboard"
                        display="flex"
                        flexDirection="row"
                        gap={7}
                        y={127}
                        onRight={() => viewRef.setFocus()}

                    >
                        <Button
                            height={120}
                            width={120}
                            class="key"
                            onEnter={() => handleClick("q")}
                        >
                            q
                        </Button>
                        <Button
                            height={120}
                            width={120}
                            class="key"
                            onEnter={() => handleClick("w")}
                        >
                            w
                        </Button>
                        <Button
                            height={120}
                            width={120}
                            class="key"
                            onEnter={() => handleClick("e")}
                        >
                            e
                        </Button>
                        <Button
                            height={120}
                            width={120}
                            class="key"
                            onEnter={() => handleClick("r")}
                        >
                            r
                        </Button>
                        <Button
                            height={120}
                            width={120}
                            class="key"
                            onEnter={() => handleClick("t")}
                        >
                            t
                        </Button>
                        <Button
                            height={120}
                            width={120}
                            class="key"
                            onEnter={() => handleClick("y")}
                        >
                            y
                        </Button>
                        <Button
                            height={120}
                            width={120}
                            class="key"
                            onEnter={() => handleClick("u")}
                        >
                            u
                        </Button>
                        <Button
                            height={120}
                            width={120}
                            class="key"
                            onEnter={() => handleClick("i")}
                        >
                            i
                        </Button>
                        <Button
                            height={120}
                            width={120}
                            class="key"
                            onEnter={() => handleClick("o")}
                        >
                            o
                        </Button>
                        <Button
                            height={120}
                            width={120}
                            class="key"
                            onEnter={() => handleClick("p")}
                        >
                            p
                        </Button>
                    </Row>
                    <Row
                        ref={row3}
                        onUp={() => row2.setFocus()}
                        onDown={() => row4.setFocus()}
                        class="keyboard"
                        display="flex"
                        flexDirection="row"
                        gap={7}
                        y={254}
                        onRight={() => viewRef.setFocus()}

                    >
                        <Button
                            height={120}
                            width={120}
                            class="key"
                            onEnter={() => handleClick("a")}
                        >
                            a
                        </Button>
                        <Button
                            height={120}
                            width={120}
                            class="key"
                            onEnter={() => handleClick("s")}
                        >
                            s
                        </Button>
                        <Button
                            height={120}
                            width={120}
                            class="key"
                            onEnter={() => handleClick("d")}
                        >
                            d
                        </Button>
                        <Button
                            height={120}
                            width={120}
                            class="key"
                            onEnter={() => handleClick("f")}
                        >
                            f
                        </Button>
                        <Button
                            height={120}
                            width={120}
                            class="key"
                            onEnter={() => handleClick("g")}
                        >
                            g
                        </Button>
                        <Button
                            height={120}
                            width={120}
                            class="key"
                            onEnter={() => handleClick("h")}
                        >
                            h
                        </Button>
                        <Button
                            height={120}
                            width={120}
                            class="key"
                            onEnter={() => handleClick("j")}
                        >
                            j
                        </Button>
                        <Button
                            height={120}
                            width={120}
                            class="key"
                            onEnter={() => handleClick("k")}
                        >
                            k
                        </Button>
                        <Button
                            height={120}
                            width={120}
                            class="key"
                            onEnter={() => handleClick("l")}
                        >
                            l
                        </Button>
                        <Button
                            height={120}
                            width={120}
                            class="key"
                            onEnter={handleEnter}
                        >
                            Enter
                        </Button>
                    </Row>
                    <Row
                        ref={row4}
                        onUp={() => row3.setFocus()}
                        onDown={() => row5.setFocus()}
                        class="keyboard"
                        display="flex"
                        flexDirection="row"
                        gap={7}
                        y={381}
                        onRight={() => viewRef.setFocus()}

                    >
                        <Button
                            height={120}
                            width={120}
                            class="key"
                            onEnter={() => handleClick("z")}
                        >
                            z
                        </Button>
                        <Button
                            height={120}
                            width={120}
                            class="key"
                            onEnter={() => handleClick("x")}
                        >
                            x
                        </Button>
                        <Button
                            height={120}
                            width={120}
                            class="key"
                            onEnter={() => handleClick("c")}
                        >
                            c
                        </Button>
                        <Button
                            height={120}
                            width={120}
                            class="key"
                            onEnter={() => handleClick("v")}
                        >
                            v
                        </Button>
                        <Button
                            height={120}
                            width={120}
                            class="key"
                            onEnter={() => handleClick("b")}
                        >
                            b
                        </Button>
                        <Button
                            height={120}
                            width={120}
                            class="key"
                            onEnter={() => handleClick("n")}
                        >
                            n
                        </Button>
                        <Button
                            height={120}
                            width={120}
                            class="key"
                            onEnter={() => handleClick("m")}
                        >
                            m
                        </Button>
                        <Button
                            height={120}
                            width={374}
                            class="key"
                            onEnter={handleBackspace}
                            ref={backspace}
                        >
                            Backspace
                        </Button>
                    </Row>
                    <Row
                        ref={row5}
                        onUp={() => row4.setFocus()}
                        class="keyboard"
                        display="flex"
                        flexDirection="row"
                        gap={120}
                        y={508}
                        onRight={() => viewRef.setFocus()}
                    >
                        <Button
                            height={120}
                            width={1263}
                            class="key"
                            onEnter={handleSpace}
                        >
                            Space
                        </Button>
                    </Row>
                </View>

                <View x={-330} y={130} width={800} height={80}>
                    <View
                        // tabIndex={0} // Makes the div focusable
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => {
                            setIsFocused(false);
                        }}
                        onEnter={() => {
                            setIsKeyboardFocused(true);
                        }}
                    >
                        <View
                            ref={search}
                            onLeft={() => {
                                row1.setFocus();
                            }}
                            onUp={() => isKeyboardFocused() && row1.setFocus()}
                            onRight={() => isKeyboardFocused() && row1.setFocus()}
                            onDown={() => {
                                isKeyboardFocused() ? row1.setFocus() : viewRef.setFocus();
                            }}
                            x={380}
                            style={[{ width: 1265, height: 90, color: 0x4F7F4F }, styles.ThumbnailChannel]}
                        >
                            {/* <Text x={40} y={25}> */}

                            {!inputValue() ? <Text x={40} y={25}>Search</Text> : <Text x={40} y={25}></Text>}
                            {/* </Text> */}
                            <Text x={40} y={25}>
                                {inputValue()}
                            </Text>
                        </View>

                    </View>
                </View>


                <Column
                    plinko
                    announce="TV Channels"
                    // scroll="none"
                    style={styles.Column}
                    ref={viewRef}
                    y={130}
                    gap={20}
                    onLeft={() => row1.setFocus()}
                // onUp={() => search.setFocus()}

                >
                    <For each={displayedItems()} fallback={<View x={1350} y={500}><Text>Loading ....</Text></View>}>
                        {(item) => (
                            <View
                                onEnter={() => { navigate(`/entity/movie/${item.stream_id}/${item.category_id}`) }}
                                x={1200}
                                style={{
                                    borderRadius: 16,
                                    width: 485,
                                    height: 165,
                                    scale: 1,
                                    zIndex: 2,
                                    color: 0x4F7F4F,
                                    transition: { scale: { duration: 250, easing: "ease-in-out" } },
                                    border: { width: 0, color: 0x00000000 },
                                    focus: { scale: 1.03, color: "0x467ffa" },
                                }}
                            >
                                <View src={item.image_stream}
                                    x={10}
                                    y={10}
                                    style={{ width: 100, height: 145, borderRadius: 10 }}
                                ></View>
                                <Text style={{ fontSize: 27 }} x={135} y={40}>
                                    {item.title.length > 30 ? item.title.slice(0, 15) + '...' : item.title}
                                </Text>
                            </View>
                        )}
                    </For>
                </Column>
            </View>
        </View>
    );
};
export default SearchPage;