//@ts-ignore
import { createEffect, createSignal, onCleanup, For } from "solid-js";
import { View, Text, hexColor } from "@lightningjs/solid";
import { useNavigate, useParams } from "@solidjs/router";
import FirstPageBackground from "../../components/FirstPageBackground";
import styles from "../../styles";
import { isVpnMode, setIsPlaying, setIsVpnMode } from "../../state";
import { Button, ButtonContainer, Column, Row } from "@lightningjs/solid-ui";
import Background from "../../components/Background";

const homeStyle: any = {
  borderRadius: 50,
  width: 550,
  height: 550,
  scale: 1,
  zIndex: 2,
  color: "0x4F7F4F",
  transition: { scale: { duration: 250, easing: "ease-in-out" } },
  border: { width: 0, color: 0x00000000 },
  focus: { scale: 1.03, color: "0x467ffa" },
  // display:'flex',
  // flexdirection: 'column',
  // justifyContent: 'center',
};
const button: any = {
  borderRadius: 20,
  scale: 1,
  zIndex: 2,
  color: "0x4F7F4F",
  transition: { scale: { duration: 250, easing: "ease-in-out" } },
  border: { width: 0, color: 0x00000000 },
  focus: { scale: 1.03, color: "0x467ffa" },
};
export function ThumbnailHome(props) {
  return (
    <View style={homeStyle} onEnter={props.onEnter}>
      <View
        src={props.src}
        width={300}
        height={300}
        mountX={-0.4}
        mountY={-0.2}
      ></View>
      <View mountX={-0.35} mountY={-0.85}>
        <Text style={{ fontSize: 50 }}>{props.data.name}</Text>
      </View>
    </View>
  );
}
export function RowHome(props) {
  return (
    <Row autofocus {...props} scroll="none" gap={40}>
      <For each={props.items}>{(item) => <ThumbnailHome {...item} />}</For>
    </Row>
  );
}
const HomePage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [time, setTime] = createSignal(new Date());
  const updateTime = () => setTime(new Date());
  const interval = setInterval(updateTime, 1000);
  onCleanup(() => clearInterval(interval));
  const onEnterCustom = () => {
    navigate("/channel-menu");
  };
  let col1;
  let col2;
  createEffect(() => {
    setIsPlaying(false);
  });
  const liveTV = () => {
    navigate("/channel-menu");
    setIsPlaying(true);
  };
  const movies = () => {
    navigate("/movies");
    setIsPlaying(true);
  };
  const series = () => {
    navigate("/series");
    setIsPlaying(true);
  };
  const playlists = () => {
    navigate("/");
  };
  const menuItems = [
    { src: "https://i.ibb.co/261M4fV/tvicon.png", data: { name: "Live TV" }, onEnter: liveTV },
    {
      src: "https://i.ibb.co/gdydbkV/moviesicon.png",
      data: { name: "Movies" },
      onEnter: movies,
    },
    {
      src: "https://i.ibb.co/2FWYD3j/seriesicon.png",
      data: { name: "Series" },
      onEnter: series,
    },
  ];
  const bottomItems = [
    // { src: "./assets/privacy_mode.png", data: { name: "Privacy Mode" } },
    { src: "./assets/settings.png", data: { name: "S" } },
    // { src: "./assets/playlists.png", data: { name: "Playlists" } }
  ];

  const togglePrivacyMode = () => {
    if (isVpnMode() === false) {
      setIsVpnMode(true);
    } else {
      setIsVpnMode(false);
    }
    console.log("kevke", isVpnMode());
  };

  return (
    <View
      onEscape={() => {
        navigate("/");
      }}
    >
      <FirstPageBackground type={2} />
      <View flexItem={false} x={30} y={15} zIndex={105}>
        <View y={30} x={30} src="/assets/logo.png" width={280} height={85} />
      </View>
      <View y={70} x={1430}>
        <Text>
          {time().toDateString()} {time().toLocaleTimeString()}
        </Text>
      </View>
      <View>
        <View width={70} x={90} y={200} onDown={() => col2.setFocus()}>
          <RowHome items={menuItems} ref={col1} />
        </View>
      </View>
      {/* <View y={800} onUp={() => col1.setFocus()}>
                <TileColumnMovie items={bottomItems} ref={col2} />
            </View> */}
      <View y={800} onUp={() => col1.setFocus()}>
        <Row
          ref={col2}
          scroll="none"
          style={{
            display: "flex",
            justifyContent: "spaceBetween",
            flexDirection: "row",
          }}
        >
          <Button
            onEnter={() => {
              togglePrivacyMode();
            }}
            style={button}
            y={100}
            width={400}
            mountX={-0.22}
            borderRadius={20}
          >
            Privacy Mode {isVpnMode() ? "Enabled" : "Disabled"}
          </Button>
          <ButtonContainer
            style={button}
            y={100}
            width={170}
            mountX={-2.0}
            borderRadius={20}
            onEnter={() => {
              navigate("/settings");
            }}
          >
            <View src="https://i.postimg.cc/hvTWsvm4/settings.png" width={40} height={40}>

            </View>

          </ButtonContainer>
          {/* <TileColumnMovie items={bottomItems}  y={100} width={100} mountX={-4} ref={col2} /> */}
          <Button
            style={button}
            y={100}
            width={400}
            mountX={0.26}
            borderRadius={20}
            onEnter={() => playlists()}
          >
            Playlists
          </Button>
        </Row>
      </View>
    </View>
  );
};
export default HomePage;
